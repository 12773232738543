import React from "react"
import { localize } from "../../Atoms/Language"

const HighQualityParts = ({className}) => (
  <div className={className}>
    <h2 className="header4">{localize("82")}</h2>
    <p className="body gray600">{localize("83")}</p>
    <p className="body gray600">{localize("84")}</p>
  </div>
)

export default HighQualityParts
