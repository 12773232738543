import React from "react"
import PartsForWashes from "../components/Organisms/StaticSections/PartsForWashes"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import HighQualityParts from "../components/Organisms/StaticSections/HighQualityParts"
import SEO from "../components/seo"
import { localize } from "../components/Atoms/Language"
import { WithIntl } from "../components/Atoms/withTranslate"

const PartsForWashesPage = ({language}) => (
  <div className="container">
    <SEO
      lang={language}
      title={localize("85")}
      description={localize("86")}
    />
    <PartsForWashes/>
    <HighQualityParts className="mb16"/>
    <CTABanner language={language}/>
  </div>
)

export default WithIntl()(PartsForWashesPage)
