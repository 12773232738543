import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import ImageWithText from "../../Molecules/ImageWithText/ImageWithText"
import { localize } from "../../Atoms/Language"

const imageQuery = graphql`
  {
    file(name: { eq: "carwash9" }) {
      childImageSharp {
        fixed(width: 445, height: 562, quality: 100){
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const StyledImageWithText = styled(ImageWithText)`
  .image {
    max-width: 445px; 
  }
  
  .text-content {
    padding-right: 30px;
  }
`

const PartsForWashes = () => {
  const data = useStaticQuery(imageQuery)

  return (
    <StyledImageWithText fixed={data.file.childImageSharp.fixed} imageAltText={localize("8")} className="mt8">
      <h1 className="header2">{localize("79")}</h1>
      <p className="body">{localize("80")}</p>
      <p className="body">{localize("81")}</p>
    </StyledImageWithText>
  )
}

export default PartsForWashes
